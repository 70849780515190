const ReactDOM  = require('react-dom')
const materialUI  = require('@material-ui/core')
const materialPickers  = require('@material-ui/pickers')
const MomentUtils  = require('@date-io/moment')  
import React, {Component} from 'react'
import DateRangePicker from 'react-daterange-picker' 
import Moment from 'moment';
import { extendMoment } from 'moment-range'; 
import DateFnsUtils from '@date-io/date-fns';
const moment = extendMoment(Moment);
export {Component,React,ReactDOM,materialUI,materialPickers, MomentUtils, DateRangePicker, moment, DateFnsUtils};    